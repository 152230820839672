import { makeStyles } from "@mui/styles";
const useStyle = makeStyles((theme) =>({
    root:{
        width:'100%',
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }
}))
export default useStyle