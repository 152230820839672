import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import PageLoader from "./components/loader/pageLoader";
import { RouterProvider } from "react-router-dom";
import Router from "./components/routes/routes";

const ThemeProvider = React.lazy(()=> import('./components/theme/theme'))
function App() {
  return (
    <HelmetProvider>
    <ThemeProvider>
    <Suspense fallback={<PageLoader />}>
        <RouterProvider router={Router} />
      </Suspense>
    </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
