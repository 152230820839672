import React from 'react';
import { createBrowserRouter } from 'react-router-dom'
const DashboardMainLayout = React.lazy(()=>import('../../layout/dashboardMainLayout/dashboardMainLayout'))
const Dashboard = React.lazy(()=>import('../../layout/dashboard/dashboard'))
const HomeCare = React.lazy(() => import ('../../layout/homeCare/homeCare'))
const PostHospitalizationCare = React.lazy(() => import ('../../layout/homeCare/servicess/postHospitalCare'))
const CriticalCare = React.lazy(() => import ('../../layout/homeCare/servicess/criticalCare'))
const ElderCare = React.lazy(() => import ('../../layout/homeCare/servicess/elderlyCare'))
const NeuroRehabCare = React.lazy(() => import ('../../layout/homeCare/servicess/neuroRehabCare'))
const OnCologyCare = React.lazy(() => import ('../../layout/homeCare/servicess/oncology'))
const OrthoCare = React.lazy(() => import ('../../layout/homeCare/servicess/orthoCare'))
const PalliativeCare = React.lazy(() => import ('../../layout/homeCare/servicess/palliativeCare'))
const TracheostomyCare = React.lazy(() => import ('../../layout/homeCare/servicess/tracheostomy'))
const SkilledNursing = React.lazy(() => import ('../../layout/homeCare/servicess/skilledNursing'))
const DoctorAtHome = React.lazy(() => import ('../../layout/homeCare/servicess/doctorAtHome'))
const Physiotherapy = React.lazy(() => import ('../../layout/homeCare/servicess/physiotherapy'))
const Router = createBrowserRouter([
    // homepage
    {
        path:'/',
        element:<DashboardMainLayout><Dashboard/></DashboardMainLayout>
    },
    // landingPages
    {
        path:'/Home-care',
        element:<DashboardMainLayout><HomeCare/></DashboardMainLayout>
    },
    // services pages
    {
        path:'/Home-care/post-hospitalization-care',
        element:<DashboardMainLayout><PostHospitalizationCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/critical-care',
        element:<DashboardMainLayout><CriticalCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/elderly-care',
        element:<DashboardMainLayout><ElderCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/neuro-rehab-care',
        element:<DashboardMainLayout><NeuroRehabCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/oncology-care',
        element:<DashboardMainLayout><OnCologyCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/ortho-care',
        element:<DashboardMainLayout><OrthoCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/palliative-care',
        element:<DashboardMainLayout><PalliativeCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/tracheostomy-care',
        element:<DashboardMainLayout><TracheostomyCare/></DashboardMainLayout>
    },
    {
        path:'/Home-care/skilled-nursing',
        element:<DashboardMainLayout><SkilledNursing/></DashboardMainLayout>
    },
    {
        path:'/Home-care/doctor-at-home',
        element:<DashboardMainLayout><DoctorAtHome/></DashboardMainLayout>
    },
    {
        path:'/Home-care/physiotherapy',
        element:<DashboardMainLayout><Physiotherapy/></DashboardMainLayout>
    },
    
])
export default Router;