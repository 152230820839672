import { useTheme } from '@mui/material'
import React from 'react'
import { SyncLoader } from 'react-spinners'
import useStyle from './style'

const PageLoader = () => {
    const theme = useTheme()
    const classes = useStyle()
  return (
    <div className={classes.root}>
        <SyncLoader color={theme.palette.secondary.main}/>
    </div>
  )
}

export default PageLoader